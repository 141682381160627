import axios, { AxiosInstance, AxiosResponse } from 'axios'
import useSWR from 'swr'
import { useSession } from 'next-auth/react'
import { api, apiV2 } from 'src/lib/axios'

export function useFetch<T>(route: string, { api3 = false }: { api3?: boolean; condition?: boolean } = {}) {
  const { data: session } = useSession()

  const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_WHATSMENU_API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session?.accessToken}`,
    },
  })

  const { data, ...swr } = useSWR<AxiosResponse<T, T>, string>(route, api, { revalidateOnFocus: false, shouldRetryOnError: false })
  return {
    ...swr,
    status: data?.status,
    data: data?.data,
  }
}
